import cn from "classnames";
import { downloadPDF } from "../../utils";

const About = ({ className = "" }) => {
  return (
    <div className={cn(className)}>
      <h1 className="title">Hi</h1>
      <p className="mt-[32px] max-w-[900px] leading-8 tracking-wide font-normal text-gray-600">
      I am a software enthusiast with over five years of experience in software development and IT auditing. As a full-stack developer, I have a strong background in both front-end and back-end development. Additionally, I am a Certified Information Systems Auditor with more than three years of experience working in that role at a Big Four auditing firm. I am highly motivated, possessing extensive knowledge and experience in software development, IT processes, and controls. I am passionate about working with new technologies and building responsive applications using various frameworks.
      </p>

      <button
        className="mt-[32px] py-3 px-8 rounded-[30px] text-white bg-primary hover:bg-dark-primary"
        
      >
        Download Resume
      </button>
    </div>
  );
};

export default About;
