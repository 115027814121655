import cn from "classnames";
import ResumeList from "../../components/resume-list/resume-list";
import ExperienceLoader from "../../components/experience-loader/experience-loader";

const education = [
  {
    id: 1,
    title: "Georgia Institute of Technology",
    duration: "2022 - 2024",
    descriptionList: [
      "Information Security ",
      "Modeling, Simulation, and Military Gaming ",
      "Big Data Sys & Analytics ",
      "Information security Policies ", 
      "Information Policy & Mgt ", 

    ],
  },

  {
    id: 2,
    title: "University of St Thomas",
    duration: "2015 - 2019",
    descriptionList: [
      "Intro - Programming & Prob Solving - Comp Sci",
      "Intro - Computer Technology & Business Application",
      "Multi-Variable Calculus",
      "Calculus II",
      "Engineering Graphics & Design",

    ],
  }
];

const experience = [
  {
    id: 1,
    title: "U.S. Department of Defense - Software Developer",
    duration: "2022 - Present",
    descriptionList: [
      "Design and develop the UI interface for projects and resolved issues and responsible for its enhancements according to the needs of the users.",
      "Design robust, scalable and globalized web-based application used to analyze data.",
      "Implement proper version control amongst the team and review merge requests.",
      "Implement user-friendly UI/UX design standards to ensure applications are intuitive for users and compliant with standards.",
      "Manage 1300+ personnel’s identity provisioning and access with Active Directory admin rights.",
      "Create new connections through applications for better access to JSON and involved in writing several functions, sequences, stored procedures, triggers, cursors and object types.",
      "Used Python and PowerShell to executes procedures remotely to devices needing updates and facing other issues.",
      "Used ServiceNow ticketing software to prioritize tickets that needed to be worked on and divided tasks using a Agile and Kanban methodology.",
      "Manage 1300+ personnel’s identity provisioning and access with Active Directory admin rights.",
      "Designed, documented and executed maintenance procedures, such as system upgrades, patch management, and system.",
      "Created Standard operating procedures (SOP) to support faculty on the operation and use of multimedia equipment and to explain the capabilities and limitations of said equipment.",
      "Troubleshot software and hardware issues through remote access software and in person visits for various devices such as computers, Tablets, and other electronic devices.",
      "Used PowerShell to perform tasks within windows OS.",
      "Used automated scripting to perform automation with Python.",
    ], 
  },

  {
    id: 2,
    title: "PricewaterhouseCoopers LLC - IT Risk & Audit Associate",
    duration: "2019 - 2022",
    descriptionList: [
      "Perform planning, evidence gathering, testing, documentation, and review on IT general controls and business process reviews.",
      "Create Standard operating procedures (SOP) for associates and service delivery centers to efficiently perform process controls testing.",
      "Make Tablueu and Power BI dashboards on a weekly basis to show team the progress of tasks.",
      "Host daily standup meetings with teams to discuss workflows and issues faced.",
      "Automate audit test procedures using Alteryx Workflows, and UiPath to simplify processes.",
      "Analyzed customer queries and technical issues to identify software changes and confirmed that change management procedures were properly followed.",
      "Managed engagement teams by setting task timelines, focusing on deliverables, identifying risks troubleshooting issues and managing client contacts.",
      "Analyzed and reviewed code used to change system configurations within applications.",

    ],
  },


];

const Resume = ({ className = "" }) => {
  return (
    <div className={cn(className)}>
      <h1 className="title">Resume</h1>

      <p className="mt-[32px] max-w-[900px] leading-8 tracking-wide font-normal text-gray-600">
        Technical Skills : JavaScript, Tablueau, PowerBI, Python, Express,
        Pandas, MySQL, Heroku
      </p>

      <div className="mt-[48px]">
        <p className="tracking-[0.2rem] font-medium text-gray-500">EDUCATION</p>

        <ResumeList data={education} className="mt-[48px]" />

        <p className="mt-[48px] tracking-[0.2rem] font-medium text-gray-500">
          EXPERIENCE
        </p>

        <ResumeList data={experience} className="mt-[48px]" />

        <p className="mt-[48px] tracking-[0.2rem] font-medium text-gray-500">
          GENERAL SKILLS
        </p>

        <div className="mt-[48px] grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[100px]">
          <ExperienceLoader title="HTML" level={70} />
          <ExperienceLoader title="CSS" level={80} />
          <ExperienceLoader title="React" level={70} />
          <ExperienceLoader title="Django" level={50} />
          <ExperienceLoader title="Fast API" level={70} />
          <ExperienceLoader title="Tailwind CSS" level={70} />
        </div>
      </div>
    </div>
  );
};

export default Resume;
